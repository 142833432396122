import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { Flight, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import "./leftMenu.css"

const MAX_DISPLAY_ITEM = 7

function Icon(props: { icon: string, height: number }) {
  const style = {
    width: props.height / 50 + "px",
    display: "block",
    margin: "auto",
    marginTop: -props.height / 60,
  }

  return (
    <div style={{ position: "absolute", width: "75%" }}>
      {props.icon === "Flight" &&
        <Flight style={style}>
        </Flight>
      }
    </div>
  )
}

function LeftMenu() {
  const { width, height, leftMenu, activeScene, mainColor, activeOrbit } = useState(hive.state)
  const scrollIndex = useState(0);


  const isScoll = (up: boolean) => {
    const index = scrollIndex.get();
    if (up && index > 0) {
      return true;
    }
    if (!up) {
      if (index + MAX_DISPLAY_ITEM < leftMenu.length) {
        return true;
      }
    }
    return false;
  }

  const scroll = (up: boolean) => {
    const index = scrollIndex.get();
    if (up && index > 0) {
      scrollIndex.set(index - 1)
      return;
    }
    if (!up) {
      if (index + MAX_DISPLAY_ITEM < leftMenu.length) {
        scrollIndex.set(index + 1)
        return;
      }
    }
  }

  return (
    <div className="leftMenu" style={
      {
        top: height.get() / 2 -
          (
            ((leftMenu.length > MAX_DISPLAY_ITEM ? MAX_DISPLAY_ITEM : leftMenu.length) * height.get() / 12) +
            (height.get() / 22 * 2)
          ) / 2,
        left: width.get() / 100,
        width: width.get() / 20 + "px",
        fontSize: width.get() / 90 + "px",
        zIndex : 3
      }}>

      {leftMenu.length > MAX_DISPLAY_ITEM &&
        <div className="left-menu-scene-top" style={{
          height: height.get() / 22 + "px",
          lineHeight: height.get() / 22 + "px",
          boxShadow: `0px 0px ${height.get() / 50}px 0px rgba(0,0,0,0.22)`
        }} onClick={() => scroll(true)}>
          <KeyboardArrowUp style={{
            width: height.get() / 25 + "px",
            height: height.get() / 25 + "px",
            marginTop: height.get() / 180 + "px",
            color: isScoll(true) ? `#${mainColor.get()}` : 'inherit',
            cursor: isScoll(true) ? `pointer` : 'initial',
          }}></KeyboardArrowUp>
        </div>
      }

      {leftMenu
        .filter((s, i) => i - scrollIndex.get() >= 0)
        .filter((s, i) => i < MAX_DISPLAY_ITEM)
        .map((scene, i) =>
          <div
            onClick={() => {
              hive.action(scene.action.get(), scene.target.get())
              // hive.setScene(scene.target.get())
            }}
            key={i} style={{
              height: height.get() / 15 + "px",
              lineHeight: height.get() / 15 + "px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: (scene.target.get() === activeScene.get() || scene.target.get() === activeOrbit.get()) ? 'initial' : 'pointer',
              color: (scene.target.get() === activeScene.get() || scene.target.get() === activeOrbit.get()) ? 'white' : 'inherit'
            }}>
            {scene.icon.get() &&
              <Icon icon="Flight" height={height.get()}></Icon>
            }
            {(scene.target.get() === activeScene.get() || scene.target.get() === activeOrbit.get()) &&
              <div style={{
                width: '95%',
                height: height.get() / 20 + "px",
                lineHeight: height.get() / (scene.icon.get() ? 15 : 20) + "px",
                background: `#${mainColor.get()}`,
                borderRadius: height.get() / 120 + "px"
              }}>
                {scene.text.get()}
              </div>
            }{(scene.target.get() !== activeScene.get() && scene.target.get() !== activeOrbit.get()) &&
              <div style={{
                height: height.get() / 20 + "px",
                lineHeight: height.get() / (scene.icon.get() ? 15 : 20) + "px",
              }}>
                {scene.text.get()}
              </div>
            }
          </div>
        )}

      {leftMenu.length > MAX_DISPLAY_ITEM &&
        <div className="left-menu-scene-top" style={{
          height: height.get() / 22 + "px",
          lineHeight: height.get() / 22 + "px",
          boxShadow: `0px 0px ${height.get() / 50}px 0px rgba(0,0,0,0.22)`
        }} onClick={() => scroll(false)}>
          <KeyboardArrowDown style={{
            width: height.get() / 25 + "px",
            height: height.get() / 25 + "px",
            marginTop: height.get() / 180 + "px",
            color: isScoll(false) ? `#${mainColor.get()}` : 'inherit',
            cursor: isScoll(false) ? `pointer` : 'initial',
          }}></KeyboardArrowDown>
        </div>
      }

    </div>
  );
}


export default LeftMenu;
